@import "../settings/colors";

.layout{
  margin-top: 40px;
  padding: 0 80px;
  margin-bottom: 50px;
}

h1{
  margin-bottom: 30px;
  text-align: center;
}

.selectRow{
  padding: 0 200px 0 200px;
}

.selectCol:hover{
  cursor: pointer;
}

.select{
  padding-bottom: 5px;
  text-align: center !important;
}

.select[status="active"]{
  border-bottom: 2px solid $menu_btn_active;
}

.form{
  margin-top: 30px;
}

.text_input{
  width: 500px;
  border-radius: 20px;
  padding: 5px 10px;
  border: 2px solid $menu_btn_active;
  display: block;
  margin: auto;
}

.text_input:focus{
  outline: none;
}

.submit{
  padding: 5px 50px;
  border-radius: 20px;
  border: 0px;
  background: $menu_btn_active;
  color: $white-color;
  display: block;
  margin: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.file_input{
  display: inline-block;
  margin-left: 5px;
  margin-right: 15px;
}

.avatar_zone{
  width: 200px;
  height: 200px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.overlay_layer{
  width: 200px;
  height: 40px;
  position: absolute;
  margin-top: -40px;
  opacity: 0.5;
  background-color: #000000;
  z-index: 0;
  font-size: 25px;
  color: $white-color;
  text-align: center;
  line-height: 40px;
}

.upload_btn{
  position: absolute;
  width: 200px;
  height: 200px;
  margin-top: -200px;
  z-index: 10;
  opacity: 0;
}

.camera_icon{
  margin-top: -250px;
  font-size: 80px;
  color: $white-color;
}

.files{
  text-align: center;
}

@media screen and (max-width: 1200px){
  .selectRow{
    padding: 0 100px 0 100px;
  }
}

@media screen and (max-width: 992px){
  .selectRow{
    padding: 0;
  }

  .avatar_zone{
    width: 150px;
    height: 150px;
  }

  .overlay_layer{
    width: 150px;
    font-size: 20px;
  }

  .upload_btn{
    width: 150px;
    height: 150px;
    margin-top: -150px;
  }

  .camera_icon{
    margin-top: -210px;
    font-size: 60px;
  }
}

@media screen and (max-width: 768px){
  .text_input{
    width: 100%;
  }

  .avatar_zone{
    width: 125px;
    height: 125px;
  }

  .overlay_layer{
    width: 125px;
    font-size: 16px;
  }

  .upload_btn{
    width: 125px;
    height: 125px;
  }

  .camera_icon{
    margin-top: -190px;
    font-size: 55px;
  }
}

@media screen and (max-width: 520px){
  .layout{
    padding: 0 30px;
  }

  .avatar_zone{
    width: 100px;
    height: 100px;
  }

  .overlay_layer{
    height: 25px;
    width: 100px;
    font-size: 14px;
    margin-top: -25px;
    line-height: 25px;
  }

  .upload_btn{
    width: 100px;
    height: 100px;
    margin-top: -100px;
  }

  .camera_icon{
    margin-top: -145px;
    font-size: 50px;
  }

  .file_input{
    margin-right: 10px;
  }
}

@media screen and (max-width: 420px){
  .avatar_zone{
    width: 80px;
    height: 80px;
  }

  .overlay_layer{
    width: 80px;
    font-size: 11px;
  }

  .upload_btn{
    width: 80px;
    height: 80px;
    margin-top: -80px;
  }

  .camera_icon{
    margin-top: -130px;
    font-size: 50px;
  }

  .file_input{
    margin-left: 5px;
    margin-right: 10px;
  }
}