@import "../settings/colors";

.blue_button{
  border: 0px;
  border-radius: 15px;
  font-size: 20px;
  padding: 5px 30px;
  background: $menu_btn_active;
  color: $white-color;
  text-align: center;
  display: block;
  margin: auto;

  &:hover{
    color: $white-color;
  }
}

.buttonIcon{
  position: relative;
  top: -1px;
}