@import "../settings/colors";

.start_image{
  background: url("../../images/startImage.png") no-repeat center center;
  height: 500px;
  margin-bottom: 120px;
}

.block{
  background: rgba(0, 0, 0, 0.9);
  height: 300px;
  width: 500px;
  opacity: 0.8;
  position: absolute;
  top: 180px;
  right: 30px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 22px 9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px 9px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 22px 9px rgba(0,0,0,0.75);
}

.start_message{
  color: $white-color;
  margin-top: 0px;
  position: absolute;
  top: 230px;
  right: 100px;
  font-size: 40px;
}

.start_contact{
  border: 0px;
  border-radius: 15px;
  font-size: 25px;
  padding: 5px 60px;
  background: $menu_btn_active;
  color: $white-color;
  position: absolute;
  top: 380px;
  right: 170px;
  text-align: center;

  &:hover{
    color: $white-color;
  }
}

.start_image p{
  color: white;
  text-align: center;
  width: 100%;
  font-size: 35px;
  position: relative;
  top: 40%;
}

.skills_layout{
  margin-top: 120px;
  margin-bottom: 120px;
}

.skills{
  width: 100px;
  display: block;
  margin: 30px auto 0 auto;
}

.skillsTitle {
  display: block;
  font-family: bold;
  font-size: 25px;
  text-align: center;
  margin-top: 10px;
}

.popularServices{
  background: $menu_btn_active;
  color: $white-color;
  padding-top: 20px;
  margin-top: 120px;
  margin-bottom: 120px;
}

.popularServicesTitle{
  font-size: 35px;
  margin: 10px 0 50px 0;
  font-family: bold;
}

.servicesIcon{
  display: block;
  margin: auto;
  font-size: 80px;
  color: $white-color;
}

.servicesText{
  color: $white-color;
  margin: 10px 0 20px 0;
  font-size: 20px;
}

.textBetter{
  text-align: center;
  width: 500px;
  display: block;
  margin: 50px auto 0 auto;
}

.viewMore{
  width: 250px;
  display: block;
  margin: 50px auto 0px auto;
}

@media screen and (max-width: 1200px){
  .start_message{
    float: left;
    right: 50%;
    transform: translate(50%);
  }

  .block{
    float: left;
    right: 50%;
    transform: translate(50%);
  }

  .start_contact{
    float: left;
    right: 50%;
    transform: translate(50%);
  }
}

@media screen and (max-width: 782px){
  .start_message{
    width: 500px;
  }

  .textBetter{
    width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 550px){
  .start_message{
    width: 100%;
  }
}

@media screen and (max-width: 500px){
  .block{
    width: 100%;
  }
}

@media screen and (max-width: 400px){
  .start_contact{
    margin-top: -60px;
  }
}

@media screen and (max-width: 350px){
  .start_message{
    font-size: 35px;
  }
}

@media screen and (max-width: 398px){
  .start_message{
    top: 260px;
  }

  .start_contact{
    top: 450px;
  }
}