@import "../settings/colors.scss";

.contacts_layout{
  margin-top: 50px;
  padding: 0 80px;
}

.contacts_layout p{
  text-align: left;
}

.info{
  color: $menu_btn_inactive;
}

.icon_name{
  font-size: 24px;
  color: $menu_btn_active;
  vertical-align: -5px;
}

.dot_name{
  font-size: 24px;
  color: $menu_btn_active;
  vertical-align: -7px;
}

.icon_phone{
  font-size: 20px;
  color: $menu_btn_active;
}

.icon_email{
  font-size: 22px;
  color: $menu_btn_active;
}

a{
  text-decoration: none;
  color: $text-color;

  &:hover{
    color: $text-color;
  }
}

.infoBlock p{
  text-align: left;
}

.sectionTitle{
  margin-top: 120px;
}

@media screen and (max-width: 768px){
  .contacts_layout{
    padding: 12px;
  }
}