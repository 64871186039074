@import "../settings/colors";

h1{
  margin: 50px 0 50px 0;
}

h3{
  text-align: center;
  margin-top: 30px;
}

.servicesIcon{
  display: block;
  margin: auto;
  font-size: 80px;
  color: $menu_btn_active;
}

.servicesText{
  color: $menu_btn_active;
  margin: 10px 0 20px 0;
  font-size: 20px;
}

.subtitle{
  margin-bottom: 30px;
}