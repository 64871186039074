@import "../settings/colors";

.starContainer{
  display: inline-block;
}

.stars{
  text-align: center;
}

.star{
  margin: 20px 5px 5px 5px;
  font-size: 40px;

  &:hover{
    color: $yellow_color_hover;
  }
}

.starActive{
  color: $yellow_color;

  @extend .star;
}

.starInactive{
  color: $light-gray;

  @extend .star;
}

.starsTip{
  color: $menu_btn_inactive;
}