@import "../settings/colors";

.quote{
  height: 500px;
  background: $light-blue-background;
  box-shadow: 0 0 10px 5px $light-blue-background;
}

.quoteTitle{
  margin: 80px 0px 20px 0px;
}

.quoteText{
  font-size: 20px;
  position: relative;
  left: 10px;
}

.quoteImage{
  height: 500px;
  width: 100%;
  float: right;
  margin-right: -12px;
}

@media screen and (max-width: 1235px){
  .quoteTitle{
    font-size: 30px;
  }

  .quoteText{
    font-size: 18px;
  }
}

@media screen and (max-width: 1080px){
  .quoteTitle{
    font-size: 26px;
  }

  .quoteText{
    font-size: 16px;
  }
}

@media screen and (max-width: 930px){
  .quoteTitle{
    font-size: 24px;
  }

  .quoteText{
    font-size: 15px;
    left: 0px;
  }
}

@media screen and (max-width: 767px){
  .quoteTitle{
    margin: 40px 0px 20px 0px;
  }

  .quoteImage{
    height: 400px;
    width: 300px;
    display: block;
    margin: 20px auto;
    border-radius: 15px;
    float: none;
  }

  .quote{
    height: 100%;
  }
}