@import "../settings/colors.scss";

.menu_btn{
  font-size: 16px;
  text-decoration: none;
  padding-left: 15px;
  font-weight: bold;
}

.menu_btn_active{
  color: $menu_btn_active;
  @extend .menu_btn;

  transition: 0.3s;
  &:hover{
    transition: 0.3s;
    color: $menu_btn_active;
    font-size: 20px;
  }
}

.menu_btn_inactive{
  color: $menu_btn_inactive;
  @extend .menu_btn;

  transition: 0.3s;
  &:hover{
    transition: 0.3s;
    color: $menu_btn_active_light;
    font-size: 20px;
  }
}

.header_layout{
  z-index: 999;
  padding: 10px 30px;
  //box-shadow: 0 2px 20px 0 #dcdcdc;
}

.logo{
  width: 70px;
  margin-right: 20px;
  display: inline-block;
}

.links{
  display: inline-block;
}

.contact{
  float: right;
  color: $menu_btn_active;
  //color: $white-color;
  //background: $menu_btn_active;
  //border-radius: 20px;
  //padding: 0px 10px;
  margin-top: 5px;
  font-size: 30px;

  &:hover{
    //color: $white-color;
    color: $menu_btn_active;
  }
}

@media screen and (max-width: 600px){
  .header_layout{
    padding-left: 10px;
  }
  .logo{
    margin-right: 0;
  }
}

@media screen and (max-width: 550px){
  .contact{
    display: none;
  }
}

@media screen and (max-width: 429px){
  .logo{
    display: block;
    margin: 0 auto 10px auto;
  }
  .links{
    display: block;
    text-align: center;
  }
}

//@media screen and (max-width: 340px){
//
//}