@import "../settings/colors";

.start_image{
  background: url("../../images/startImageDark.png") no-repeat center center;
  height: 500px;
}

.start_message{
  color: $white-color;
  margin-top: 0px;
  font-size: 60px;
  text-align: center;
  padding-top: 220px;
}

.servicesIcon{
  font-size: 50px;
  color: $menu_btn_active;
  vertical-align: -5px;
}

.back{
  color: $white-color;
  font-size: 16px;
  position: relative;
  top: 10px;
  left: 20px;

  &:hover{
    color: $white-color;
  }
}

.description{
  margin-top: 60px;
  text-align: left;
}

.darkText{
  color: $hr-light-gray;
}

.image{
  height: 500px;
  display: block;
  margin: 70px auto 0 auto;
  border-radius: 15px;
}

.imageHorizontal{
  width: 100%;
  display: block;
  margin: 70px auto 0 auto;
  border-radius: 15px;
}

span{
  font-family: bold;
  font-weight: bold;
}

@media screen and (max-width: 767px){
  .image{
    margin: 20px auto 0 auto;
  }
}

@media screen and (max-width: 498px){
  .start_message{
    padding-top: 150px;
  }
}