@import "../settings/colors.scss";

.footer_layout{
  margin-bottom: 20px;
}

.logo{
  display: block;
  margin: auto;
  width: 100px;
}

hr{
  width: 80%;
  margin: 50px auto 30px auto;
  color: $hr-light-gray;
}

p{
  color: $text-color;
  text-align: center;
}

.arrow{
  font-size: 35px;
  display: block;
  text-align: center;
  color: $menu_btn_active;

  transition: 0.3s;
  &:hover{
    transition: 0.3s;
    color: $menu_btn_active;
    margin-top: -10px;
  }
}

.footer_description a{
  text-decoration: none;
  color: $footer_links;

  transition: 0.3s;
  &:hover{
    transition: 0.3s;
    color: $menu_btn_active;
  }
}

.mediaLinks{
  text-align: center;
}

.mediaIcons{
  width: 40px;
  margin: -20px 10px;
}

@media screen and (max-width: 800px){
  .logo{
    width: 80px;
  }
}

@media screen and (max-width: 500px){
  .logo{
    width: 60px;
  }
}

@media screen and (max-width: 447px){
  .footer_description{
    font-size: 14px;
  }
}

@media screen and (max-width: 400px){
  .footer_description{
    font-size: 13px;
  }
}

@media screen and (max-width: 375px){
  .footer_description{
    font-size: 12px;
  }
}