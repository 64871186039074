@import "../settings/colors.scss";

.alert{
  position: fixed;
  bottom: 30px;
  right: 20px;
  border-radius: 15px;
  width: 250px;
  padding-top: 18px;
}

.alert[type="GREEN"]{
  background: $green-background;
}

.alert[type="RED"]{
  background: $red-background;
}

.alertText[type="GREEN"]{
  color: $green-color;
}

.alertText[type="RED"]{
  color: $red-color;
}

.alertIcon{
  font-size: 20px;
  position: relative;
  top: -2px;
}