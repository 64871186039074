@import "../settings/colors.scss";

.portfolio_layout{
  margin-top: 50px;
}

.portfolio_layout p{
  text-align: left;
}

.info{
  color: $menu_btn_inactive;
}

.slider_layout{
  margin-top: 60px;
}

.carouselImages{
  border-radius: 15px;
  width: 100%;
  height: 600px;
}

.carouselImagesVertical{
  width: 330px;
  height: 600px;
  border-radius: 15px;
  display: block;
  margin: auto;
}

h2{
  text-align: center;
  margin: 40px 0 20px 0;
}

@media screen and (max-width: 1200px){
  .carouselImages{
    height: 500px;
  }
}

@media screen and (max-width: 992px){
  .carouselImages{
    height: 400px;
  }
}

@media screen and (max-width: 768px){
  .carouselImages{
    height: 300px;
  }
}

@media screen and (max-width: 500px){
  .carouselImages{
    height: 250px;
  }
}

@media screen and (max-width: 430px){
  .carouselImages{
    height: 220px;
  }
}

@media screen and (max-width: 380px){
  .carouselImages{
    height: 200px;
  }
}