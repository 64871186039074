@import "colors.scss";

@font-face {
  font-family: 'bold';
  src: url(../../../public/fonts/Nunito-Bold.ttf);
}

@font-face {
  font-family: 'medium';
  src: url(../../../public/fonts/Nunito-Medium.ttf);
}

@font-face {
  font-family: 'light';
  src: url(../../../public/fonts/Nunito-Light.ttf);
}

body{
  font-family: "medium";
  //font-family: 'Trebuchet MS', sans-serif;
  color: $text-color;
}

h1{
  font-family: "bold";
}