@import "../settings/colors";

.heart{
  color: $red-background;
}

.stars{
  float: right;
}

.star{
  margin-top: 15px;
  font-size: 20px;
}

.starActive{
  color: $yellow_color;

  @extend .star;
}

.starInactive{
  color: $light-gray;

  @extend .star;
}

.clientName{
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 3px;
  left: 10px;
}

.clientPhoto{
  display: inline-block;
  width: 50px;
  border-radius: 25px;
}

.clientDescription{
  margin-top: 20px;
}

.review{
  background: $white-color;
  width: 500px;
  display: block;
  margin: 0px auto 20px auto;
  border-radius: 15px;
  padding: 10px;
}

.reviews{
  margin-top: 120px;
  margin-bottom: 80px;
  background: $light-blue-background;
  padding: 30px 0px 70px 0px;
  box-shadow: 0 0 15px 10px $light-blue-background;
}

.reviewsTitle{
  margin-bottom: 40px;
}

.reviewButtons{
  width: 550px;
  margin-top: 20px;
}

.reviewButtons button{
  margin-top: 20px;
}

.buttonIcon{
  position: relative;
  top: -1px;
}

@media screen and (max-width: 600px){
  .review{
    width: 90%;
  }

  .reviewButtons{
    width: 100%;
  }
}