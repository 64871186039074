$menu_btn_inactive: #838383;
$menu_btn_active: #63bef5;
$footer_links: #4eaae3;
$dark_blue_color: #378cc0;
$yellow_color: #ffd780;
$yellow_color_hover: #ffee80;
$gray-background: #313131;
$light-blue-background: #f2f3f7;
$menu_btn_active_light: #a1cde8;
$text-color: #3b3b3b;
$hr-light-gray: #888888;
$light-gray: #b2b2b2;
$white-color: white;
$green-background: rgba(0, 255, 79);
$green-color: rgb(2, 143, 0);
$red-background: rgb(255, 54, 54);
$red-color: rgb(180, 0, 0);