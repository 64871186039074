.certificate{
  width: 500px;
  display: block;
  margin: 120px auto 0px auto;
}

@media screen and (max-width: 550px){
  .certificate{
    width: 90%;
  }
}